<template>
  <div>
    <CContainer style="margin-top: 156px" class="d-flex align-items-center justify-content-center mb-5">
      <CRow class="justify-content-center">
        <CCol md="8" lg="12">
          <CCardGroup class="has-loading-overlay">
            <CCard class="p-4">
              <CCardBody>
                <ValidationObserver ref="reset" v-slot="{ handleSubmit }">
                  <CForm @submit.prevent="handleSubmit(onSubmit)">
                  <h1>Reset</h1>
                  <p class="text-muted">Resetting password for your account {{getUserDetails()}}</p>
                  <CRow class="row mb-3">
                    <label class="required col-lg-3 col-md-12">Password</label>
                    <div class="col-lg-4 col-md-6 col-sm-12">
                      <ValidationProvider
                        name="password"
                        :rules="{ required: true, password_length: 8, password_strength: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/ }"
                        v-slot="{errors}"
                      >
                        <TextInput
                          name="password"
                          type="password"
                          :value="password"
                          @input="handleInput"
                          :error="errors[0]"
                        />
                        <small
                          class="hint"
                        >Password format: 8 characters - containing upper & lower case letters, numbers and a special character. No words.</small>
                      </ValidationProvider>
                    </div>
                  </CRow>
                  <CRow class="row mb-3">
                    <label class="required col-lg-3 col-md-12">Confirm Password</label>
                    <div class="col-lg-4 col-md-6 col-sm-12">
                      <ValidationProvider rules="required|confirmed:password" v-slot="{errors}">
                        <TextInput
                          name="confirmPassword"
                          :value="confirmPassword"
                          @input="handleConfirmPassword"
                          type="password"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                  <CRow>
                    <CCol col="6">
                      <CButton
                        type="button"
                        color="danger"
                        class="px-4"
                        @click="redirectToLogin"
                      >{{"Cancel"}}</CButton>
                    </CCol>
                    <CCol col="6">
                      <CButton type="submit" color="primary" class="px-4">{{"Reset"}}</CButton>
                    </CCol>
                  </CRow>
                </CForm>
              </ValidationObserver>
              </CCardBody>
            </CCard>
          </CCardGroup>
        </CCol>
      </CRow>
    </CContainer>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { parseJwt } from "@/helpers/helper";
import { extend } from "vee-validate";
import {
  required,
  confirmed,
  regex,
  min
} from "vee-validate/dist/rules";

extend("required", { ...required, message: "This field is required" });
extend("password_length", {
  ...min,
  message: "Password be atleast 8 characters"
});
extend("password_strength", {
  ...regex,
  message: "Password must have capitals, numbers and special characters"
});
extend("confirmed", {
  ...confirmed,
  message: "This field should match password"
});

import TextInput from "@/components/reusable/Fields/TextInput";
import { LOGIN_URL } from '../../helpers/helper';

export default {
  name: "Reset",
  components: {
    TextInput
  },
  data() {
    return {
      password: "",
      confirmPassword: ""
    };
  },
  computed: {
    ...mapGetters(["getUserData"])
  },
  methods: {
    ...mapActions(["reset", "showToast"]),
    handleConfirmPassword(name, value) {
      this.confirmPassword = value;
    },
    handleInput(name, value) {
      this.password = value;
    },
    resetSubmit() {
      const token = this.$route.query.token;
      this.reset({
        token: token,
        new_password: this.password,
        confirmPassword: this.confirmPassword
      });
    },
    getUserDetails() {
      let getUserData = this.$route.query.token;
      if (getUserData) {
        return parseJwt(getUserData).email;
      } else {
        return "";
      }
    },
    redirectToForgot() {
      this.$router.push({ path: "/auth/forgot" });
    },
    redirectToLogin() {
      this.$router.push({ path: LOGIN_URL() });
    },
    async onSubmit() {
      const isValid = await this.$refs.reset.validate();
      if (!isValid) {
        this.showToast({class: 'bg-danger text-white', message: "passwords does not match criteria"})
        return;
      }
      this.resetSubmit()
    }
  }
};
</script>
